import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import Loading from './Loading.vue';
import store from '@/store';
import { getAuth, signOut } from 'firebase/auth';

export type routerOptions = { homePath: string, withAgreement: boolean };

const token = '/token/:token';

const makeRouter = (folder: string, additionalRoutes: RouteRecordRaw[] = []) => {
    const routes: RouteRecordRaw[] = [
        { path: '/', component: () => import(`@/${folder}/Home.vue`) },
        { path: '/login', component: () => import('./Login.vue') },
        {
            path: '/admin',
            component: () => import('./Admin/index.vue'),
            children: [
                { path: 'results/:qualifier?', component: () => import('./Admin/Results.vue') },
                { path: 'did-not-fill', component: () => import('./Admin/DidNotFill.vue') },
                { path: 'comments', component: () => import('./Admin/Comments.vue') },
                { path: '', component: () => import('./Admin/Home.vue') },
            ]
        },
        { path: `${token}`, component: Loading },
        { path: `${token}/demographics`, component: () => import(`@/${folder}/Demographics.vue`) },
        { path: `${token}/questionnaire`, component: () => import('./Pages/Questionnaire.vue') },
        { path: `${token}/final`, component: () => import(`@/${folder}/Final.vue`) },
        ...additionalRoutes
    ]

    const router = createRouter({ history: createWebHistory(), routes });

    router.beforeEach(async (to, from) => {
        requestAnimationFrame(() => document.querySelector('main')?.scrollTo(0,0));
        if(!store.state.firebase!.loaded) // If user is loading, wait before determining proper routing
            await new Promise(resolve => {
                store.watch(state => state.firebase!.loaded, (value: boolean) => {
                    if(value === true)
                        resolve(null);
                });
            });

        const { user, claims } = store.state.firebase!;

        const isFinal = (route: RouteLocationNormalized) => route.path.endsWith('final');

        if(to.path === '/' && user && claims.isSubject){
            await signOut(getAuth());
            return;
        }

        if(to.path.startsWith('/admin') && (!user || claims.isSubject))
            return '/';
        else if(to.path.split('/').length > 3 && !to.path.includes('admin') && (isFinal(from) || (!store.hasModule('study') && !isFinal(to))))
            return `/token/${to.params.token}`;

        return;
    });

    return router;
}

export default makeRouter;