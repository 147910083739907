import { App } from '@vue/runtime-core';
import makeRouter from './router';
import { FirebasePlugin } from '@/plugins/FirebasePlugin';
import { FirebaseOptions } from 'firebase/app';
import { Router, RouteRecordRaw } from 'vue-router';

export let router: Router | null = null;

const appSetupFactory = (firebaseConfig: FirebaseOptions, folder: string, additionalRoutes?: RouteRecordRaw[]) => {
    return (app: App<Element>) => {
        router = makeRouter(folder, additionalRoutes);
        app.use(FirebasePlugin(firebaseConfig, router));

        return { router, app };
    }
}

export default appSetupFactory;