<template>
	<div id='loading'>
		<img v-if='!notFound' src='/images/loading-page.gif' />
		<div v-if='!notFound' :style='{ maxWidth: "20rem" }'>אנחנו מחפשים את השאלון שלך</div>
		<div v-else :style='{ marginTop: "7rem" }'>
            נראה שמשהו בקישור שלך לא תקין. לא הצלחנו למצוא את השאלון - מתנצלים על אי הנוחות!
        </div>
	</div>
</template>

<script lang="ts">
import studyModule from './module';
import chatModule from '../Chat/module';
import { defineComponent } from '@vue/runtime-core';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { getFunctions, httpsCallable } from "firebase/functions";
import { sendResponses, getAppHelpers, tokenResult } from './helpers';
import store, { setUser } from '@/store';
import { router } from './main';
import { firebase } from '../plugins/FirebasePlugin';

// import { connectFunctionsEmulator } from 'firebase/functions';

export const load = async () => {
    try{
        if(!firebase.app) throw new Error('Firebase app is null!');
        
        const functions = getFunctions(firebase.app, 'europe-west3');
        // connectFunctionsEmulator(functions, "localhost", 5001);

        if(!store.hasModule('study'))
            store.registerModule('study', studyModule);
            
        const { token } = router!.currentRoute.value.params;

        const result = await httpsCallable(functions, 'findToken')(token);
        const data = result.data as tokenResult;
        if(data.error)
            throw new Error('An error occured...');

        setUser({ sex: data.sex });

        await signInWithCustomToken(getAuth(), data.customToken!);

        return data;
    }catch(error){
        console.error(error);
        return { notFound: true };
    }
}

export default defineComponent({
	async beforeMount() {
        const data = await load();
        if('notFound' in data)
            return this.notFound = true;


        if(!store.hasModule('chat'))
            store.registerModule('chat', chatModule);

        const { getRouteFromData } = await getAppHelpers();
        const route: string | { stop: true } = getRouteFromData(data);
        if(typeof route !== 'string' && 'stop' in route) return;

        sendResponses({ entered: true, goingTo: route });
        this.$router.push(`/token/${this.$route.params.token}/${route}`);
	},
    data: () => ({ requestSent: false, notFound: false }),
});
</script>

<style lang="scss">
#loading {
    font-size: 1.8rem;
    color: #999;
    font-weight: 500;
    margin: 15vh auto 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    & > div {
        max-width: 28rem;
    }
}
</style>