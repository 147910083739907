import { StudyState } from '@/ts/state/StudyState';
import { namespacedToCommit } from '@/helpers/store';

const mutations = {
    setHasCorona: (state: StudyState, hasCorona: boolean) => {
        state.hasCorona = hasCorona;
    }
}

const toCommit = namespacedToCommit('study');

export const setHasCorona = toCommit(mutations.setHasCorona);

export default {
    namespaced: true,
    state: {
        dem: { age: null, button: 'continue' },
        hasCorona: null,
        sex: null,
        id: null,
        type: null
    } as StudyState,
    mutations
}
